import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getUserWSConnectionObject } from "services/common.service";

function MarketPrice(props) {

  const [marketPrice, setMarketPrice] = useState('');   
  
  useEffect(() => {
    let socket;

    // Subscribe channels
    const subscribeChannels = async () => {
        await socket.subscribe({
          "name": "spot_price",
          "symbols": [
            ".DEXBTUSDT"
          ]
        });
    }

    // socket message call back function
    const socketMessageMP = (e) => {      
      const res = JSON.parse(e.data);
      if (res.type === 'spot_price')
      {       
        setMarketPrice(parseFloat(res.price).toFixed(2)); 
      }
      
      socket.ws.onclose = () => {        
          connectSocket();
      }      
    }

    // socket connection 
    const connectSocket = async () => {     
        try{
      if (!socket) {
        socket = await getUserWSConnectionObject();
      }  

      if (socket && !socket.connected) {   
		
        await socket.connect();        
		
      }

      //set callback function
      socket.callbacks.push(socketMessageMP);

      await subscribeChannels();    
	    }catch(e){
			console.log("unable to connect")
		  }
    }     
    
    connectSocket()        
  }, []);
  

  return (
    marketPrice ? marketPrice : 'Loading...'
  );
}

export default MarketPrice;
